import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { TermsConditionsComponent } from './layout/unauth/terms-conditions/terms-conditions.component';
import { RoleGuard } from './services/role.guard';
import { TcGuard } from './services/tc.guard';
import { environment as env } from '../environments/environment';
import { LogoutComponent } from './logout/logout.component';
import { MainComponent } from './layout/main/main.component';
import { UnauthComponent } from './layout/unauth/unauth.component';
import { IsAdminGuard } from './services/authguard.service';
import { FileProgressGuard } from './services/file-progress.guard';
import { invitedUserGuard } from './services/invited-user.guard';

const ROUTE = {
  MAIN: 'm',
  NOT_FOUND: 'nf',
  UNAUTH: 'u',
  LOGIN: 'login',
  LOGOUT: 'logout',
  APP_LOBBY: 'applobby',
  PROJECTS: 'projects',
  STUDIES: 'studies',
  ORGANIZATION: 'organization',
  INBOX: 'inbox',
  AUDIT: 'audit',
  USERS: 'users',
  USER_MANAGEMENT: 'usermanagement',
  PO_USERS: 'pousers',
  DATACATALOG: 'datacatalog',
  BUDGET: 'budget',
  TERMS_CONDITIONS: 'termsconditions',
};

const MENU = {
  DASHBOARD: 'Dashboard',
  ORGANIZATION: 'Organization',
  VIRTUAL_LABS: 'Virtual Labs',
  INBOX: 'Inbox',
  STUDY_AUDIT: 'Study Audit',
  USERS: 'Users',
  DATA: 'Data',
  BUDGET:'Budget',
  TERMS_CONDITIONS: 'TermsConditions',
};

const routes: Routes = [
  {
    path: '',
    redirectTo: ROUTE.LOGIN,
    pathMatch: 'full',
  },
  {
    path: ROUTE.LOGIN,
    component: LoginComponent,
  },
  {
    path: ROUTE.LOGOUT,
    canActivate: [FileProgressGuard],
    component: LogoutComponent,
  },
  {
    path: ROUTE.MAIN,
    component: MainComponent,
    data: { breadcrumb: '' },
    children: [
      {
        path: ROUTE.APP_LOBBY,
        loadChildren: () =>
          import('../../projects/dashboard/src/app/app.module').then(
            (m) => m.AppModule
          ),
        canActivate: [IsAdminGuard, RoleGuard, TcGuard],
        data: {
          selected_menu: [MENU.DASHBOARD],
          allowedRoles: [
            env.config.roles.XCAP_PLATFORM_ADMIN,
            env.config.roles.starcap_platform_admin,
            env.config.roles.imcore_scientific_director,
            env.config.roles.inspector_gadget,
            env.config.roles.imcore_member,
            env.config.roles.imcore_member_data_ingress_only,
            env.config.roles.data_controller,
            env.config.roles.imcore_guest,
            env.config.roles.site_dpo_gpo,
          ],
        },
      },
      {
        path: ROUTE.PROJECTS,
        loadChildren: () =>
          import('../../projects/studies/src/app/app.module').then(
            (m) => m.AppModule
          ),
        canActivate: [IsAdminGuard, RoleGuard, TcGuard, invitedUserGuard],
        data: {
          selected_menu: [MENU.VIRTUAL_LABS],
          breadcrumb: MENU.VIRTUAL_LABS,
          path: `${ROUTE.MAIN}/${ROUTE.STUDIES}`,
          title: MENU.VIRTUAL_LABS,
          allowedRoles: [
            env.config.roles.imcore_scientific_director,
            env.config.roles.inspector_gadget,
            env.config.roles.imcore_member,
            env.config.roles.imcore_member_data_ingress_only,
            env.config.roles.data_controller,
            env.config.roles.site_dpo_gpo,
          ],
        },
      },
      {
        path: ROUTE.ORGANIZATION,
        loadChildren: () =>
          import('../../projects/orgmanagement/src/app/app.module').then(
            (m) => m.AppModule
          ),
        canActivate: [IsAdminGuard, RoleGuard, TcGuard, invitedUserGuard],
        data: {
          selected_menu: [MENU.ORGANIZATION],
          breadcrumb: MENU.ORGANIZATION,
          path: ROUTE.ORGANIZATION,
          allowedRoles: [
            env.config.roles.XCAP_PLATFORM_ADMIN,
            env.config.roles.starcap_platform_admin,
          ],
        },
      },
      {
        path: ROUTE.INBOX,
        loadChildren: () =>
          import('../../projects/inbox/src/app/app.module').then(
            (m) => m.AppModule
          ),
        canActivate: [IsAdminGuard, RoleGuard, TcGuard, invitedUserGuard],
        data: {
          selected_menu: [MENU.INBOX],
          breadcrumb: MENU.INBOX,
          path: ROUTE.INBOX,
          allowedRoles: [
            env.config.roles.XCAP_PLATFORM_ADMIN,
            env.config.roles.starcap_platform_admin,
            env.config.roles.data_controller,
            env.config.roles.site_dpo_gpo,
            env.config.roles.imcore_scientific_director,
          ],
        },
      },
      {
        path:ROUTE.BUDGET,
        loadChildren:()=>import('../../projects/budget/src/app/budget.module').then(
          (m)=>m.BudgetModule
        ),
        canActivate:[IsAdminGuard,RoleGuard,TcGuard,invitedUserGuard],
        data:{
          selected_menu:[MENU.BUDGET],
          breadcrumb:MENU.BUDGET,
          path:ROUTE.BUDGET,
          allowedRoles:[
            env.config.roles.XCAP_PLATFORM_ADMIN,
            env.config.roles.starcap_platform_admin,
            env.config.roles.data_controller,
            env.config.roles.site_dpo_gpo,
            env.config.roles.imcore_scientific_director,
          ]
        }
      },
      {
        path: ROUTE.AUDIT,
        loadChildren: () =>
          import('../../projects/audit/src/app/app.module').then(
            (m) => m.AppModule
          ),
        canActivate: [IsAdminGuard, RoleGuard, TcGuard, invitedUserGuard],
        data: {
          selected_menu: [MENU.STUDY_AUDIT],
          breadcrumb: MENU.STUDY_AUDIT,
          path: ROUTE.AUDIT,
          allowedRoles: [env.config.roles.site_dpo_gpo],
        },
      },
      {
        path: ROUTE.USERS,
        loadChildren: () =>
          import('../../projects/users/src/app/app.module').then(
            (m) => m.AppModule
          ),
        canActivate: [IsAdminGuard, RoleGuard, TcGuard, invitedUserGuard],
        data: {
          selected_menu: [MENU.USERS],
          breadcrumb: MENU.USERS,
          path: ROUTE.USER_MANAGEMENT,
          allowedRoles: [
            env.config.roles.XCAP_PLATFORM_ADMIN,
            env.config.roles.starcap_platform_admin,
          ],
        },
      },
      {
        path: ROUTE.PO_USERS,
        loadChildren: () =>
          import('../../projects/users/src/app/app.module').then(
            (m) => m.AppModule
          ),
        canActivate: [IsAdminGuard, RoleGuard, TcGuard, invitedUserGuard],
        data: {
          selected_menu: [MENU.USERS],
          breadcrumb: MENU.USERS,
          path: ROUTE.USER_MANAGEMENT,
          allowedRoles: [
            env.config.roles.imcore_scientific_director,
            env.config.roles.inspector_gadget,
            env.config.roles.imcore_member,
            env.config.roles.imcore_member_data_ingress_only,
            env.config.roles.data_controller,
          ],
        },
      },
      {
        path: ROUTE.DATACATALOG,
        loadChildren: () =>
          import('../../projects/datacatalog/src/app/data-catalog.module').then(
            (m) => m.DataCatalogModule
          ),
        canActivate: [IsAdminGuard, RoleGuard, TcGuard, invitedUserGuard],
        data: {
          selected_menu: [MENU.DATA],
          breadcrumb: MENU.DATA,
          path: ROUTE.DATACATALOG,
          allowedRoles: [
            env.config.roles.imcore_member,
            env.config.roles.imcore_member_data_ingress_only,
            env.config.roles.data_controller,
          ],
        },
      },
      {
        path: ROUTE.NOT_FOUND,
        pathMatch: 'full',
        component: NotfoundComponent,
      },
    ],
  },
  {
    path: ROUTE.UNAUTH,
    component: UnauthComponent,
    children: [
      {
        path: '',
        component: TermsConditionsComponent,
        data: {
          breadcrumb: MENU.TERMS_CONDITIONS,
          path: ROUTE.TERMS_CONDITIONS,
        },
      },
    ],
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: `${ROUTE.MAIN}/${ROUTE.NOT_FOUND}`,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      canceledNavigationResolution: 'computed',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
